body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:visited{
  color:inherit;text-decoration: none;
}
/*
.code_edit_container {
  position: fixed;
  bottom:0;
  left:0;
  border-left:6px solid #111;
  border-top:6px solid #111;
  height: 400px;
  width:100%;
  margin: 0;
  transition: all 0.4s ease-in-out;
}*/
.page_editor_opened,
main.page_editor_opened{
  margin-left:320px !important;
}
main{
  margin:0px;
  transition: all 0.4s ease-in-out;
}
.hide{
  display:none !important;
}

.page_editor.opened{
  left:0px !important;
}
.page_editor{
  height:100vh;
  position: absolute;
  left:-250px;
  bottom:0;
  padding:10px;
}

.ListItem{}
.ListItem.available{}
.ListItem.in_progress{
  background-color: rgb(172, 112, 21);
}
.ListItem.broken{
  background-color: rgba(172, 37, 21, 0.5);
}
.ListItem.disabled{
  opacity:0.5;
}

.new_element_dropzone{
  display:block;
  text-align: center;
  font-size:16px;
  padding:15px;
}

.leaflet-container {
  height: 400px;
  width: 100%;
  z-index: -1;
  position: relative;
}

.new_element_dropzone.active{
background-color:goldenrod;
}
.droppable_pulse {
  border: 2px dashed red;
  box-shadow: 0 0 0 rgba(255, 0, 0, 0.4);
  animation: pulse 1s infinite;
}
.droppable_pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}